<template>
  <svg width="24" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.5 6.5L4.5 4.5C4.5 3.39543 5.39543 2.5 6.5 2.5V2.5C7.60457 2.5 8.5 3.39543 8.5 4.5L8.5 6.5"
      stroke="currentColor" stroke-linecap="round" />
    <path
      d="M2.19435 8.66782C2.33942 6.92692 2.41196 6.05647 2.98605 5.52824C3.56013 5 4.4336 5 6.18053 5H6.81947C8.5664 5 9.43987 5 10.014 5.52824C10.588 6.05647 10.6606 6.92692 10.8057 8.66782L10.8195 8.83391C10.904 9.84745 10.9462 10.3542 10.6491 10.6771C10.352 11 9.84345 11 8.8264 11H4.1736C3.15655 11 2.64802 11 2.35092 10.6771C2.05382 10.3542 2.09605 9.84745 2.18051 8.83391L2.19435 8.66782Z"
      stroke="currentColor" />
  </svg>

</template>

<script>
export default {
  name: 'bag',
}
</script>
